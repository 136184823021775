<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <h4>2) Select production plan</h4>
            </v-col>
        </v-row>
        <!-- Product selector -->
        <v-row dense class="pt-3">
            <v-col cols="12">
                <v-autocomplete
                    v-model="selectedProductionPlan"
                    label="Plan #"
                    outlined
                    dense
                    :items="productionPlanList"
                    item-text="plan_number"
                    return-object
                    :disabled="isLoading"
                    @change="updateSelectedProductionPlan"
                >

                </v-autocomplete>
            </v-col>
        </v-row> 
        <!-- Work centers -->
        <span v-if="selectedProductionPlan">
            <v-row dense class="pt-3">
                <v-col cols="8">
                    <h4>3) Select work centers to create instructions for</h4>
                </v-col>
            </v-row>
            <v-row dense class="pt-3">
                <v-col cols="12">
                    <v-data-table
                        v-model="selectedWorkCenters"
                        :headers="workCenterTableHeaders"
                        :items="workCenters"
                        selectable-key="work_center_id"
                        show-select
                        item-key="work_center_id"
                        :items-per-page="500"
                        hide-default-footer
                        :loading="isLoading"
                    > 
                    </v-data-table>
                </v-col>
            </v-row>
        </span>
        <!-- Instructions -->
        <div class="mt-3" v-if="selectedWorkCenters && selectedWorkCenters.length > 0">
            <v-row dense class="pt-3">
                <v-col cols="12">
                    <h4>4) Review the instruction(s)</h4>
                </v-col>
            </v-row>
            <!--Instructions data-->
            <v-row>
                <v-col>
                    <v-tabs v-model="tab">
                        <v-tab v-for="(instruction, index) in instructionList" :key="'tab-' + index">
                            {{ instruction.work_center.work_center_name }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(instruction, index) in instructionList" :key="'tab-item-' + index">
                            <v-card class="pa-2">
                                <v-data-table
                                    :headers="partTableHeaders"
                                    :items="instruction.parts"
                                    :items-per-page="500"
                                    class="elevation-0"
                                    hide-default-footer
                                    dense
                                    group-by="assembly_name"
                                >
                                    <!--Group header-->
                                    <template v-slot:group.header="{ headers, items }">
                                        <th
                                        :colspan="headers.length"
                                        class="indigo lighten-5"
                                        style="height: 36px"
                                        >
                                        {{
                                            items[0].assembly_name
                                        }}
                                        </th>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </div>
        <!-- Submit instructions -->
        <div class="py-5" v-if="instructionList && instructionList.length !== 0">
            <v-row dense class="pt-3">
                <v-col cols="12">
                    <h4>5) Create and save</h4>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense v-model="instructionDateIssued" label="Date Issued" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="instructionDateIssued" @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-btn :disabled="!valid || isLoading" :loading="isLoading" color="primary" @click="saveInstructions">Save Instructions</v-btn>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import db from '../../../components/firebaseInit';

export default {
    name: "InstructionFormProductionPlan",
    data() {
        return {
            valid: true,
            menu: false,
            isLoading: false,
            instructionDateIssued: new Date().toISOString().substr(0, 10),
            instructionCount: null,
            selectedProductionPlan: null,
            productionPlanList: [],
            productList: [],
            workCenters: [],
            selectedWorkCenters: [],
            workCenterTableHeaders: [
                { text: 'Name', value: 'work_center_name' },
            ],
            tab: null,
            partTableHeaders: [
                { text: 'Part #', value: 'part_number' },
                { text: 'Material', value: 'part_material.material_item_name' },
                { text: 'Name', value: 'part_name' },
                { text: 'Uom', value: 'part_uom' },
                { text: 'Thickness', value: 'part_thickness' },
                { text: 'Width', value: 'part_width' },
                { text: 'Length', value: 'part_length' },
                { text: 'Part Qty / assembly', value: 'part_quantity' },
                { text: "Assembly Qty Required", value: 'assembly_quantity_required' },
                { text: 'Total Qty Required', value: 'total_quantity' },
            ],
            uniqueAssemblyIds: [],
        }
    },
    computed: {
        instructionList() {
            if (!this.selectedWorkCenters.length) return [];

            return this.selectedWorkCenters.map((workCenter) => ({
                work_center: workCenter,
                parts: this.filterAndCalculateParts(workCenter.work_center_name),
            }));
        },
    },
    methods: {
        async getProductionPlanList() {
            const docRef = db.collection("production_plans").where("plan_status", "==", "Created").orderBy("plan_number");

            try {
                const snapshot = await docRef.get();

                this.productionPlanList = snapshot.docs.map((doc) => doc.data());

            } catch (error) {
                console.error(error);
            }
        },
        updateSelectedProductionPlan(plan) {
            this.isLoading = true;
            this.selectedProductionPlan = plan;
            this.productList = [];
            this.workCenters = [];

            this.productList = this.selectedProductionPlan.plan_items.flatMap((item) => {
                item.line_item_details[0].product_name = item.combined_name.split("-")[0];
                return item.line_item_details
            });

            const productIds = this.productList.map(doc => doc.product_id);

            this.fetchProductAssemblies(productIds);
           
        },
        async fetchProductAssemblies(productIds) {
            try {
                const uniqueProductIds = productIds.reduce((list, id) => {
                    if (!list.includes(id)) list.push(id);
                    return list;
                }, []);

                const productPromises = uniqueProductIds.map(async (id) => {
                    const productDoc = await db.collection('products').doc(id).get();
                    const productData = productDoc.data();
                    const hasAssemblies = productData.product_assigned_assemblies && productData.product_assigned_assemblies.length > 0;
                    if (!hasAssemblies) return;

                    // Augment each product with the assembly data
                    this.productList.forEach(({ product_id }, index) => {
                        if (product_id === id) this.$set(this.productList[index], 'product_assigned_assemblies', productData.product_assigned_assemblies);
                    })
                
                });

                await Promise.all(productPromises);

                // remove all products without assemblies
                const productsWithAssemblies = this.productList.filter(item => item.product_assigned_assemblies);
                
                this.productList = productsWithAssemblies;
                
                this.fetchAssemblyParts(productsWithAssemblies);

            } catch (error) {
                this.isLoading = false;
                console.error(error);
            }
        },
        async fetchAssemblyParts(productsWithAssemblies) {
            try {
                // Group products by ids so we only fetch assembly parts once for each product
                const groupBy = (array, key) => {
                    return array.reduce((list, item) => {
                        (list[item[key]] = list[item[key]] || []).push(item);
                        return list;
                    }, {});
                };

                const groupedProductsWithAssemblies = groupBy(productsWithAssemblies, "product_id");

                const uniqueAssemblyIds = Object
                    .values(groupedProductsWithAssemblies)
                    .flatMap(x => x[0].product_assigned_assemblies)
                    .reduce((list, { assembly_id }) => {
                        if (!list.includes(assembly_id)) list.push(assembly_id);
                        return list;
                    }, []);  
                
                this.uniqueAssemblyIds = uniqueAssemblyIds;

                const assemblyPromises = uniqueAssemblyIds.map(async (id) => {
                    const docRef = await db.collection('assemblies').doc(id).get();
                    const assemblyData = docRef.data();

                    const hasParts = assemblyData.assembly_parts && assemblyData.assembly_parts.length > 0;
                    
                    if (!hasParts) return;

                    // Augment each product assembly with parts data
                    this.productList.forEach(({ product_assigned_assemblies }, index) => {
                        product_assigned_assemblies.forEach((assembly, i) => {
                            if (assembly.assembly_id === id) {
                                this.$set(this.productList[index].product_assigned_assemblies[i], "assembly_number", assemblyData.assembly_number);
                                this.$set(this.productList[index].product_assigned_assemblies[i], "assembly_parts", assemblyData.assembly_parts);
                            }
                        });
                    })
                });
                await Promise.all(assemblyPromises);

                this.populateWorkCentersList()
            } catch (error) {
                this.isLoading = false;
                console.error(error);
            }
        },
        populateWorkCentersList() {
            try {
                const uniqueWorkCenters = [];

                if (this.productList && this.productList.length > 0) {

                    this.productList
                        .flatMap(x => x.product_assigned_assemblies)
                        .flatMap(x => x.assembly_parts)
                        .forEach(({ part_work_center }) => {
                            if (!uniqueWorkCenters.some(wc => wc.work_center_id === part_work_center.work_center_id)) {
                                    uniqueWorkCenters.push(part_work_center);
                                }
                        })
                }

                this.workCenters = uniqueWorkCenters;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                console.error(error);
            }
        },
        filterAndCalculateParts(workCenterName) {
            // Aggrgate product quantities across the production plan
            const productAggregator = {};

            this.productList.forEach(product => {
                const { product_id, product_name, item_qty, product_assigned_assemblies } = product;
                if (productAggregator[product_id]) {
                    productAggregator[product_id].total_quantity += item_qty;
                                
                } else {
                    productAggregator[product_id] = {
                        product_id,
                        product_name,
                        product_assigned_assemblies,
                        total_quantity: item_qty
                    };
                }
            });

            // Aggrgate assembly quantities multiplied by product quantities

            const assemblyAggregator = {};

            Object.values(productAggregator).forEach((product) => {
                product.product_assigned_assemblies.forEach(assembly => {
                    const { assembly_id, assembly_name, assembly_quantity, assembly_parts } = assembly;
                
                    if (assemblyAggregator[assembly_id]) {
                        assemblyAggregator[assembly_id].total_quantity += assembly_quantity;          
                    } else {
                        assemblyAggregator[assembly_id] = {
                            assembly_id,
                            assembly_name,
                            assembly_parts,
                            total_quantity: assembly_quantity * product.total_quantity
                        };
                    }
                });
            });

            // HAPPY UP TILL HERE

            const partList = [];

            Object.values(assemblyAggregator).forEach((assembly) => {
                    // Loop over each part in that assembly and calculate requirements
                    assembly.assembly_parts.forEach(part => {
                        if (part.part_work_center.work_center_name === workCenterName) {

                            const partQuantityNeeded = part.part_quantity * assembly.total_quantity;

                            partList.push({
                                ...part,
                                assembly_name: assembly.assembly_name,
                                assembly_id: assembly.assembly_id,
                                assembly_quantity_required: assembly.total_quantity,
                                total_quantity: partQuantityNeeded
                            });
                            
                        }
                    });

                });

            return partList;
        },
        //
        async saveInstructions() {
                if (!this.$refs.form.validate()) return;  // Ensure the form is valid before proceeding.
                this.isLoading = true;
                const batch = db.batch();
                const instructionCounterRef = db.collection("instruction_counter").doc("zaOcdAJWkRQDuBi8TAsG");

                // Prepare instructions to be saved and calculate the new instruction count
                let newInstructionCount = this.instructionCount;
                this.instructionList.forEach((instruction) => {
                    const docRef = db.collection('production_instructions').doc();  // Create a new document reference for each instruction
                    
                    const instructionData = {
                        instruction_date_issued: this.instructionDateIssued,
                        instruction_status: 'Created',
                        instruction_type: 'ProductionPlan',
                        production_plan_id: this.selectedProductionPlan.plan_id,
                        production_plan_number: this.selectedProductionPlan.plan_number,
                        instruction_number: `${String(newInstructionCount + 1).padStart(4, "0")}`,
                        instruction_work_center: instruction.work_center,
                        instruction_assemblies: [{
                            parts: instruction.parts,
                        }],
                    };

                    batch.set(docRef, instructionData);  // Add this instruction to the batch
                    newInstructionCount++;  // Increment local counter for each instruction
                });

                // Update the instruction count in the batch
                batch.update(instructionCounterRef, { instruction_count: newInstructionCount });

                try {
                    await batch.commit();  // Commit the batch
                    this.instructionCount = newInstructionCount;  // Update local state to reflect the new count
                    this.$router.push('/production-instructions');  // Navigate to the instructions list page or dashboard
                } catch (error) {
                    console.error('Error saving instructions:', error);
                } finally {
                    this.isLoading = false;
                }
            },
        //
        getInstructionNumber() {
            const docRef = db.collection("instruction_counter").doc("zaOcdAJWkRQDuBi8TAsG");

            docRef.onSnapshot((snapshot) => {
                const { instruction_count } = snapshot.data();
                this.instructionCount = instruction_count;
            });
        },
    },
    created() {
        this.getProductionPlanList();
        this.getInstructionNumber();
    }
}
</script>
