<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <h4>2) Select product</h4>
            </v-col>
        </v-row>
        <!-- Product selector -->
        <v-row dense class="pt-3">
            <v-col cols="8">
                <v-autocomplete
                    v-model="selectedProduct"
                    label="Product Name"
                    outlined
                    dense
                    :items="productList"
                    item-text="product_name"
                    return-object
                    @change="updateselectedProduct"
                >

                </v-autocomplete>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    v-model.number="quantityRequired"
                    label="Quantity"
                    type="number"
                    outlined
                    dense
                >
                </v-text-field>
            </v-col>
        </v-row> 
        <!-- Work centers  -->
        <span v-if="selectedProduct && quantityRequired > 0">
            <v-row dense class="pt-3">
                <v-col cols="8">
                    <h4>3) Select work centers to create instructions for</h4>
                </v-col>
            </v-row>
            <v-row dense class="pt-3">
                <v-col cols="12">
                    <v-data-table
                        v-model="selectedWorkCenters"
                        :headers="workCenterTableHeaders"
                        :items="workCenters"
                        selectable-key="work_center_id"
                        show-select
                        item-key="work_center_id"
                        :items-per-page="500"
                        hide-default-footer
                    > 
                    </v-data-table>
                </v-col>
            </v-row>
        </span>
         <!-- Instructions -->
         <div class="mt-3" v-if="selectedWorkCenters && selectedWorkCenters.length > 0">
            <v-row dense class="pt-3">
                <v-col cols="12">
                    <h4>4) Review the instruction(s)</h4>
                </v-col>
            </v-row>
            <!--Instructions data-->
            <v-row>
                <v-col>
                    <v-tabs v-model="tab">
                        <v-tab v-for="(instruction, index) in instructionList" :key="'tab-' + index">
                            {{ instruction.work_center.work_center_name }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(instruction, index) in instructionList" :key="'tab-item-' + index">
                            <v-card class="pa-2">
                                <v-data-table
                                    :headers="partTableHeaders"
                                    :items="instruction.parts"
                                    :items-per-page="500"
                                    class="elevation-0"
                                    hide-default-footer
                                    dense
                                    group-by="assembly_name"
                                >
                                    <!--Group header-->
                                    <template v-slot:group.header="{ headers, items }">
                                        <th
                                        :colspan="headers.length"
                                        class="indigo lighten-5"
                                        style="height: 36px"
                                        >
                                        {{
                                            items[0].assembly_name
                                        }}
                                        </th>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </div>
        <!-- Submit instructions -->
        <div class="py-5" v-if="instructionList && instructionList.length !== 0">
            <v-row dense class="pt-3">
                <v-col cols="12">
                    <h4>5) Create and save</h4>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense v-model="instructionDateIssued" label="Date Issued" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="instructionDateIssued" @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-btn :disabled="!valid || isLoading" :loading="isLoading" color="primary" @click="saveInstructions">Save Instructions</v-btn>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
    import db from '../../../components/firebaseInit'

    export default {
        name: "InstructionFormManualProduct",
        data() {
            return {
                valid: true,
                menu: false,
                isLoading: false,
                instructionDateIssued: new Date().toISOString().substr(0, 10),
                instructionCount: null,
                productList: [],
                selectedProduct: null,
                quantityRequired: 0,
                workCenters: [],
                selectedWorkCenters: [],
                workCenterTableHeaders: [
                    { text: 'Name', value: 'work_center_name' },
                ],
                tab: null,  // Track the active tab

                partTableHeaders: [
                    { text: 'Part #', value: 'part_number'},
                    { text: 'Material', value: 'part_material.material_item_name' },
                    { text: 'Name', value: 'part_name' },
                    { text: 'Uom', value: 'part_uom' },
                    { text: 'Thickness', value: 'part_thickness' },
                    { text: 'Width', value: 'part_width' },
                    { text: 'Length', value: 'part_length' },
                    { text: 'Qty per assembly', value: 'part_quantity' },
                    { text: 'Total Qty Required', value: 'total_quantity' },
                ],
            }
        },
        computed: {
            instructionList() {
                if (!this.selectedWorkCenters.length) return [];

                return this.selectedWorkCenters.map((workCenter) => ({
                    work_center: workCenter,
                    parts: this.filterAndCalculateParts(workCenter.work_center_name),
                }));
            },
        },
        methods: {
            async getProducts() {
                const docRef = db.collection("products").where("product_status", "==", "Available").orderBy("product_name");

                const snapshot = await docRef.get();
                // Only populate list with products that have assemblies assigned to them
                this.productList = snapshot.docs.reduce((list, doc) => {
                    const data = doc.data();

                    if (data.product_assigned_assemblies && data.product_assigned_assemblies.length !== 0) {
                        list.push(data)
                    }
                    return list;
                }, []);
            },
            //
            updateselectedProduct(product) {
                this.selectedProduct = product;
                this.fetchWorkCenters(); // Fetch work centers when a product is selected
            },
            //
            async fetchWorkCenters() {
                if (!this.selectedProduct || !this.selectedProduct.product_assigned_assemblies || this.selectedProduct.product_assigned_assemblies.length === 0) {
                    this.workCenters = [];
                    return;
                }

                const uniqueWorkCenters = [];
                const assemblyPromises = this.selectedProduct.product_assigned_assemblies.map(async (assembly, index) => {
                    const assemblyDoc = await db.collection('assemblies').doc(assembly.assembly_id).get();
                    const assemblyData = assemblyDoc.data();

                    // Augment the original assembly object with fetched parts data
                    this.$set(this.selectedProduct.product_assigned_assemblies, index, {
                        ...assembly,
                        assembly_parts: assemblyData ? assemblyData.assembly_parts : []
                    });

                    if (assemblyData && assemblyData.assembly_parts) {
                        assemblyData.assembly_parts.forEach(part => {
                            if (part.part_work_center && part.part_work_center.work_center_id) {
                                // Check if the work center is already in the array
                                if (!uniqueWorkCenters.some(wc => wc.work_center_id === part.part_work_center.work_center_id)) {
                                    uniqueWorkCenters.push(part.part_work_center); // Add new unique work center
                                }
                            }
                        });
                    }
                });

                await Promise.all(assemblyPromises);
                this.workCenters = uniqueWorkCenters; // Set the state with the filtered unique work centers
            },
            getInstructionNumber() {
                const docRef = db.collection("instruction_counter").doc("zaOcdAJWkRQDuBi8TAsG");

                docRef.onSnapshot((snapshot) => {
                    const { instruction_count } = snapshot.data();
                    this.instructionCount = instruction_count;
                });
            },
            //
            filterAndCalculateParts(workCenterName) {
                // Loop over all asemblies in selected_product
                // total_quantity = part_quantity * assembly_quantity * this.quantityRequired
                // Add assembly_id and assembly_name to final output object

                const partList = [];

                this.selectedProduct.product_assigned_assemblies.forEach((assembly) => {
                    assembly.assembly_parts
                    .filter(part => part.part_work_center.work_center_name === workCenterName)
                    .forEach(part => {
                        // Destructure the necessary properties from part
                        const {
                            part_id,
                            part_number,
                            part_revision_number,
                            part_name,
                            part_quantity,
                            part_uom,
                            part_width,
                            part_thickness,
                            part_length,
                            part_material
                        } = part;

                        // If part_material is a complex object, destructure it here
                        const { material_item_id, material_item_name } = part_material;

                        // Construct the new object using the destructured values
                        partList.push({
                            part_id,
                            part_number,
                            part_revision_number,
                            part_name,
                            part_quantity: Number(part_quantity),
                            part_uom,
                            part_width,
                            part_thickness,
                            part_length,
                            part_material: { material_item_id, material_item_name },
                            assembly_id: assembly.assembly_id,
                            assembly_name: assembly.assembly_name,
                            assembly_quantity: assembly.assembly_quantity,
                            total_quantity: part_quantity * this.quantityRequired * assembly.assembly_quantity,
                        });
                    });
                });
                
                return partList;
            },
            //
            async saveInstructions() {
                if (!this.$refs.form.validate()) return;  // Ensure the form is valid before proceeding.
                this.isLoading = true;
                const batch = db.batch();
                const instructionCounterRef = db.collection("instruction_counter").doc("zaOcdAJWkRQDuBi8TAsG");

                // Prepare instructions to be saved and calculate the new instruction count
                let newInstructionCount = this.instructionCount;
                this.instructionList.forEach((instruction) => {
                    const docRef = db.collection('production_instructions').doc();  // Create a new document reference for each instruction
                    
                    const instructionData = {
                        instruction_date_issued: this.instructionDateIssued,
                        instruction_status: 'Created',
                        instruction_type: 'ManualProduct',
                        instruction_number: `${String(newInstructionCount + 1).padStart(4, "0")}`,
                        instruction_work_center: instruction.work_center,
                        product_name: this.selectedProduct.product_name,
                        product_id: this.selectedProduct.product_id,
                        product_quantity: this.quantityRequired,
                        instruction_assemblies: [{
                            parts: instruction.parts,
                        }],
                    };

                    batch.set(docRef, instructionData);  // Add this instruction to the batch
                    newInstructionCount++;  // Increment local counter for each instruction
                });

                // Update the instruction count in the batch
                batch.update(instructionCounterRef, { instruction_count: newInstructionCount });

                try {
                    await batch.commit();  // Commit the batch
                    this.instructionCount = newInstructionCount;  // Update local state to reflect the new count
                    this.$router.push('/production-instructions');  // Navigate to the instructions list page or dashboard
                } catch (error) {
                    console.error('Error saving instructions:', error);
                } finally {
                    this.isLoading = false;
                }
            },
        },
        created() {
            this.getProducts();
            this.getInstructionNumber();
        },
    }
</script>