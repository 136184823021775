<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <h4>2) Select assembly</h4>
            </v-col>
        </v-row>
        <!-- Assembly selector -->
        <v-row dense class="pt-3">
            <v-col cols="8">
                <v-autocomplete
                    v-model="selectedAssembly"
                    label="Assembly Name"
                    outlined
                    dense
                    :items="assembliesList"
                    item-text="assembly_name"
                    return-object
                    @change="updateSelectedAssembly"
                >

                </v-autocomplete>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    v-model.number="quantityRequired"
                    label="Quantity"
                    type="number"
                    outlined
                    dense
                >

                </v-text-field>
            </v-col>
        </v-row>
        <!-- Work centers  -->
        <span v-if="selectedAssembly && quantityRequired > 0">
            <v-row dense class="pt-3">
                <v-col cols="8">
                    <h4>3) Select work centers to create instructions for</h4>
                </v-col>
            </v-row>
            <v-row dense class="pt-3">
                <v-col cols="12">
                    <v-data-table
                        v-model="selectedWorkCenters"
                        :headers="workCenterTableHeaders"
                        :items="relatedWorkCenters"
                        selectable-key="work_center_id"
                        show-select
                        item-key="work_center_id"
                        :items-per-page="500"
                        hide-default-footer
                    >
                        
                    </v-data-table>
                </v-col>
            </v-row>
        </span>
        <!-- Instructions -->
        <div class="mt-3" v-if="selectedWorkCenters && selectedWorkCenters.length > 0">
            <v-row dense class="pt-3">
                <v-col cols="12">
                    <h4>4) Review the instruction(s)</h4>
                </v-col>
            </v-row>
            <!--Instructions data-->
            <v-row>
                <v-col>
                    <v-tabs v-model="tab">
                        <v-tab v-for="(instruction, index) in instructionList" :key="'tab-' + index">
                            {{ instruction.work_center.work_center_name }}
                        </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(instruction, index) in instructionList" :key="'tab-item-' + index">
                            <v-card class="pa-2">
                                <v-data-table
                                    :headers="partTableHeaders"
                                    :items="instruction.parts"
                                    :items-per-page="500"
                                    class="elevation-0"
                                    hide-default-footer
                                    dense
                                >
                                </v-data-table>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-col>
            </v-row>
        </div>
        <!-- Submit instructions -->
        <div class="py-5" v-if="instructionList && instructionList.length !== 0">
            <v-row dense class="pt-3">
                <v-col cols="12">
                    <h4>5) Create and save</h4>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-form ref="form" v-model="valid">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field outlined dense v-model="instructionDateIssued" label="Date Issued" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="instructionDateIssued" @input="menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-btn :disabled="!valid || isLoading" :loading="isLoading" color="primary" @click="saveInstructions">Save Instructions</v-btn>
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>
<script>
    import db from '../../../components/firebaseInit'

    export default {
        name: "InstructionFormManualAssembly",
        data() {
            return {
                valid: true,
                menu: false,
                isLoading: false,
                instructionDateIssued: new Date().toISOString().substr(0, 10),
                instructionCount: null,
                assembliesList: [],
                selectedAssembly: null,
                selectedWorkCenters: [],
                quantityRequired: 0,
                workCenterTableHeaders: [
                    { text: 'Name', value: 'work_center_name' },
                ],
                tab: null,  // Track the active tab
                partTableHeaders: [
                    { text: 'Part #', value: 'part_number'},
                    { text: 'Material', value: 'part_material.material_item_name' },
                    { text: 'Name', value: 'part_name' },
                    { text: 'Uom', value: 'part_uom' },
                    { text: 'Thickness', value: 'part_thickness' },
                    { text: 'Width', value: 'part_width' },
                    { text: 'Length', value: 'part_length' },
                    { text: 'Qty per assembly', value: 'part_quantity' },
                    { text: 'Total Qty Required', value: 'total_quantity' },
                ],
            }
        },
        computed: {
            relatedWorkCenters() {
                if (!this.selectedAssembly || !this.selectedAssembly.assembly_parts) {
                    return [];
                }
                const workCenters = this.selectedAssembly.assembly_parts
                    .map(part => part.part_work_center)
                    .reduce((acc, workCenter) => {
                        if (workCenter && !acc.some(wc => wc.work_center_id === workCenter.work_center_id)) {
                            acc.push(workCenter);
                        }
                        return acc;
                    }, []);
                return workCenters;
            },
            //
            partsList() {
                if (!this.selectedAssembly || !this.selectedAssembly.assembly_parts || !this.selectedWorkCenters) {
                    return [];
                }
                return this.selectedAssembly.assembly_parts || [];
            },
            //
            instructionList() {
                if (!this.selectedWorkCenters.length) return [];

                return this.selectedWorkCenters.map((workCenter) => ({
                    work_center: workCenter,
                    parts: this.filterAndCalculateParts(workCenter.work_center_name)
                }));
            },
        },
        methods: {
            async getAssembliesList() {
                try {
                    const docRef = db.collection("assemblies").orderBy("assembly_name");
                    const snapshot = await docRef.get();
                    this.assembliesList = snapshot.docs.map(doc => doc.data());
                } catch (error) {
                    console.error("Error fetching assemblies:", error);
                }
            },
            updateSelectedAssembly(assembly) {
                this.selectedAssembly = assembly;
                this.selectedWorkCenters = [];  // Reset selected work centers when assembly changes
                this.tab = null;  // Reset the active tab
            },
            //
            filterAndCalculateParts(workCenterName) {
                return this.selectedAssembly.assembly_parts
                    .filter(part => part.part_work_center.work_center_name === workCenterName)
                    .map(part => {
                        // Destructure the necessary properties from part
                        const {
                            part_id,
                            part_number,
                            part_revision_number,
                            part_name,
                            part_quantity,
                            part_uom,
                            part_width,
                            part_thickness,
                            part_length,
                            part_material
                        } = part;

                        // If part_material is a complex object, destructure it here
                        const { material_item_id, material_item_name } = part_material;

                        // Construct the new object using the destructured values
                        return {
                            part_id,
                            part_number,
                            part_revision_number,
                            part_name,
                            part_quantity: Number(part_quantity),
                            part_uom,
                            part_width,
                            part_thickness,
                            part_length,
                            part_material: { material_item_id, material_item_name },
                            total_quantity: part_quantity * this.quantityRequired
                        };
                    });
            },
            //
            getInstructionNumber() {
                const docRef = db.collection("instruction_counter").doc("zaOcdAJWkRQDuBi8TAsG");

                docRef.onSnapshot((snapshot) => {
                    const { instruction_count } = snapshot.data();
                    this.instructionCount = instruction_count;
                });
            },
            //
            async saveInstructions() {
                if (!this.$refs.form.validate()) return;  // Ensure the form is valid before proceeding.
                this.isLoading = true;
                const batch = db.batch();
                const instructionCounterRef = db.collection("instruction_counter").doc("zaOcdAJWkRQDuBi8TAsG");

                // Prepare instructions to be saved and calculate the new instruction count
                let newInstructionCount = this.instructionCount;
                this.instructionList.forEach((instruction) => {
                    const docRef = db.collection('production_instructions').doc();  // Create a new document reference for each instruction
                    const instructionData = {
                        instruction_date_issued: this.instructionDateIssued,
                        instruction_status: 'Created',
                        instruction_type: 'ManualAssembly',
                        instruction_number: `${String(newInstructionCount + 1).padStart(4, "0")}`,
                        instruction_work_center: instruction.work_center,
                        instruction_assemblies: [{
                            assembly_name: this.selectedAssembly.assembly_name,
                            assembly_quantity: this.quantityRequired,
                            assembly_id: this.selectedAssembly.assembly_id,
                            assembly_number: this.selectedAssembly.assembly_number,
                            assembly_revision_number: this.selectedAssembly.assembly_revision_number,
                            parts: instruction.parts
                        }]
                    };

                    batch.set(docRef, instructionData);  // Add this instruction to the batch
                    newInstructionCount++;  // Increment local counter for each instruction
                });

                // Update the instruction count in the batch
                batch.update(instructionCounterRef, { instruction_count: newInstructionCount });

                try {
                    await batch.commit();  // Commit the batch
                    console.log('Instructions and counter updated successfully!');
                    this.instructionCount = newInstructionCount;  // Update local state to reflect the new count
                    this.$router.push('/production-instructions');  // Navigate to the instructions list page or dashboard
                } catch (error) {
                    console.error('Error saving instructions:', error);
                } finally {
                    this.isLoading = false;
                }
            },
        },
        created() {
            this.getAssembliesList();
            this.getInstructionNumber();
        },
    }
</script>